import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { sendEmail } from "./api";
import { Formik, Form, Field } from "formik";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { loginValidationSchema } from "./validations";
import backArrow from "../../../assets/images/back-arrow.png";
import commonStyles from "../../../common/commonStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import Spinner from "../../../components/Spinner";

const Login = () => {
  const initialValues = { email: "" };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const [isLoading, setLoading] = useState(false)

  const sendOtp = async (values, setSubmitting) => {
    // navigate(RoutesUrl.REGISTER);
    // return;
    setLoading(true)
    try {
      const res = await sendEmail({ email: values.email });
      setLoading(false)
      if (res && res.success) {
        dispatch(setUser({ ...userData, email: values.email }));
        toast.success(res.message);
        navigate(RoutesUrl.OTP);
      }
    } catch (err) {
      setLoading(false)
      console.error("Error sending email:", err);
      toast.error(err?.data?.message);
    } finally {
      setLoading(false)
      setSubmitting(false);
    }
  };

  return (
    <section className={commonStyles.authOutter}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={commonStyles.commonCard}>
              <Link to="/" className={commonStyles.backBtn}>
                <img src={backArrow} alt="" />
                Back
              </Link>
              <div
                className={[
                  commonStyles.authHeader,
                  "text-center mb-5 mt-5",
                ].join(" ")}
              >
                <h2>Welcome to Acont.ai</h2>
                <p>Please enter your details.</p>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={loginValidationSchema}
                onSubmit={(values, { setSubmitting }) =>
                  sendOtp(values, setSubmitting)
                }
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="mb-3">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Domain Email Address"
                      />
                      {errors.email && touched.email && (
                        <p className="text-danger">{errors.email}</p>
                      )}
                    </div>
                    <button
                      className={[
                        commonStyles.commonAuthBtn,
                        "mt-4",
                        "btn btn-primary",
                      ].join(" ")}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isLoading ? <Spinner /> : "Get OTP"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default React.memo(Login);
