import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {
    email: "",
    name: "",
    country_flag: "",
    country_code: "",
    phone_number: "",
    designation: "",
    category: "1",
    company_type: "1",
    company_setup_details: [],
    multiple_branch: [],
  },
};

const userReducer = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser(state, value) {
      state.data = {...value.payload };
    },
  },
});

export const { setUser } = userReducer.actions;
export default userReducer.reducer;
