import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  country_state: Yup.string().required("State is required"),
  country: Yup.string().required("Country  is required"),
  company_name: Yup.string().required("Company Name is required"),
  domain_industry: Yup.string().required("Domain is required"),
  website_link: Yup.string().required("Website Link is required"),
  gst_license_number: Yup.string().required("GST License is required"),
  address: Yup.string().required("Address is required"),
  manager_name: Yup.string().required("Manager Name is required"),
  employee_strength: Yup.string().required("Employee Strength is required"),
  revenue: Yup.string().required("Revenue is required"),
  legal_entity: Yup.string().required("legal entity is required"),
  gst_frequency: Yup.string().optional("GST frequency entity is required"),
  domestic_transaction: Yup.string().optional(
    "domestic transaction is required"
  ),
  international_transaction: Yup.string().optional(
    "international transaction is required"
  ),
});

export default validationSchema;
