import "./App.css";
import "../src/fonts/stylesheet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainRoutes from "./common/MainRoutes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <MainRoutes />
    </Router>
  );
}

export default App;
