import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import backArrow from "../../../assets/images/back-arrow.png";
import commonStyles from "../../../common/commonStyles.module.css";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { verifyOTP } from "./api";
import { toast } from "react-toastify";
import { sendEmail } from "../Login/api";
import SpinnerComp from "../../../components/Spinner";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [err, setError] = useState(false);
  const userData = useSelector((state) => state.user.data);
  const [isLoading, setLoading] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);

  const navigate = useNavigate();

  const submitOtp = (otp) => {
    setOtp(otp);
  };

  const verifyEmailOTP = async () => {
    if (otp.length === 6) {
      setError(false);
      let apiPayload = { email: userData?.email, otp };
      setLoading(true);
      try {
        const res = await verifyOTP(apiPayload);
        setLoading(false);
        if (res && res.success && !res?.data?.user) {
          navigate(RoutesUrl.REGISTER);
          toast.success(res.message);
        } else {
          toast.error("User already register");
          navigate("/");
        }
      } catch (err) {
        console.error("Error sending email:", err);
        toast.error(err?.data?.message);
        setLoading(false);
      }
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const resendOtp = async (values, setSubmitting) => {
    setResendLoader(true);
    try {
      const res = await sendEmail({ email: userData.email });
      setResendLoader(false);
      if (res && res.success) {
        toast.success(res.message);
      }
    } catch (err) {
      setResendLoader(false);
      console.error("Error sending email:", err);
      toast.error(err?.data?.message);
    }
  };

  return (
    <section className={commonStyles.authOutter}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={commonStyles.commonCard}>
              <Link to="/login" className={commonStyles.backBtn}>
                <img src={backArrow} alt="" />
                Back
              </Link>
              <div
                className={[
                  commonStyles.authHeader,
                  "text-center mb-5 mt-5 ",
                ].join(" ")}
              >
                <h2>OTP Code Verification</h2>
                <p>Code has been send to</p>
                <span>{userData?.email}</span>
              </div>
              <div className="otp-screen">
              <OtpInput
                value={otp}
                onChange={submitOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
                inputStyle={{
                  width: 40,
                  height: 40,
                  borderRadius: 10,
                  borderColor: "#B5BBC2",
                  borderWidth: 1,
                }}
              />
              </div>
              {err && <p className="text-danger">Please enter valid OTP</p>}
              <button
                onClick={verifyEmailOTP}
                className={[commonStyles.commonAuthBtn, "mt-4"].join(" ")}
              >
                {isLoading ? <SpinnerComp /> : "Verify"}
              </button>
              <p
                onClick={resendOtp}
                className={["mt-5", commonStyles.resendOtp].join(" ")}
              >
                Not received your code?{" "}
                {resendLoader ? <SpinnerComp /> : <Link>Resend code</Link>}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Otp;
