import Swal from "sweetalert2";
import { initialState, setUser } from "../redux/slices/userSlice";

export const handleSuccess = (navigate, dispatch) => {
  // Show SweetAlert notification
  Swal.fire({
    title: "Success!",
    text: "Your action was successful!",
    icon: "success",
    confirmButtonText: "Go to Home",
    backdrop: false,
  }).then((result) => {
    // If user confirms, navigate to home page
    if (result.isConfirmed) {
      dispatch(setUser(initialState.data)); // set initial state of userReducer
      navigate("/");
    }
  });
};
