import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import backBtn from "../../../assets/images/back-btn.png";
import commonStyles from "../../../common/commonStyles.module.css";
import Spinner from "../../../components/Spinner";
import { countries } from "../../../data/countries";
import { states } from "../../../data/states";
import { setUser } from "../../../redux/slices/userSlice";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { CompanyDetail } from "./api";
import validationSchema from "./validation";
import { handleSuccess } from "../../../utils/helperFunction";

const CompanySetup2 = () => {
  const navigate = useNavigate();
  const [stateByCountry, setStateByCountry] = useState([]);
  const userData = useSelector((state) => state.user.data);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    country_state: "",
    domain_industry: "",
    country: "",
    address: "",
    revenue: "",
    company_name: "",
    website_link: "",
    manager_name: "",
    legal_entity: "",
    gst_frequency: "1",
    employee_strength: "",
    gst_license_number: "",
    domestic_transaction: "1",
    international_transaction: "1",
  };

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = '';

  //     // Redirect to a specific page when the page is manually refreshed
  //     navigate('/register');
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [navigate]);

  const submitCompanyDetail = async (values, setSubmitting) => {
    let newCompanyDetails = JSON.parse(
      JSON.stringify(userData.company_setup_details)
    );
    newCompanyDetails.push(values);

    let apiData = { ...userData, company_setup_details: newCompanyDetails };
    dispatch(setUser(apiData));

    if (userData.company_type !== 1) {
      // 1 Indicate to single company type
      navigate(RoutesUrl.COMPANY_DETAILS);
      return;
    }

    setLoading(true);
    try {
      const res = await CompanyDetail(apiData);
      setLoading(false);
      if (res && res.success) {
        handleSuccess(navigate, dispatch);
        toast.success(res.message);
      }
    } catch (err) {
      setLoading(false);
      console.error("Error sending email:", err);
      toast.error(err?.data?.message);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const getState = (countryCode) => {
    setStateByCountry(
      states.filter((state) => state.countryCode === countryCode)
    );
  };

  const onSkip = () => {
    navigate(RoutesUrl.COMPANY_DETAILS);
  };

  return (
    <section
      className={[commonStyles.authOutter, commonStyles.registerOutter].join(
        " "
      )}
    >
      <Container>
        <Row>
          <Col md={12}>
            <div className={commonStyles.registerCard}>
              <div className={commonStyles.formWizard}>
                <div className="myContainer">
                  <div
                    className={[commonStyles.formContainer, "animated"].join(
                      " "
                    )}
                  ></div>
                  <h2
                    className={["text-left", commonStyles.formTitle].join(" ")}
                  >
                    Company Setup Details
                  </h2>
                  <hr />
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) =>
                      submitCompanyDetail(values, setSubmitting)
                    }
                  >
                    {({ errors, touched, isSubmitting, handleChange }) => (
                      <Form className={commonStyles.companyDetails}>
                        <Row>
                          <Col md={6}>
                            <label>
                              Company Name<span>*</span>
                            </label>
                            <Field
                              type="text"
                              name="company_name"
                              className={commonStyles.formControl}
                              placeholder="Enter company Name"
                            />
                            <ErrorMessage
                              name="company_name"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label>
                              Domain (Industry)<span>*</span>
                            </label>
                            <div className="drops">
                              <Field
                                as="select"
                                name="domain_industry"
                                className={commonStyles.formControl}
                              >
                                <option defaultValue>Select Industry</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="IT">IT</option>
                                <option value="Banking">Banking</option>
                              </Field>
                            </div>
                            <ErrorMessage
                              name="domain_industry"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label>
                              Website Link (If any)<span>*</span>
                            </label>
                            <Field
                              type="text"
                              name="website_link"
                              className={commonStyles.formControl}
                              placeholder="Enter website link"
                            />
                            <ErrorMessage
                              name="website_link"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <label>
                              Country<span>*</span>
                            </label>
                            <Field
                              className={[
                                commonStyles.formControl,
                                "form-select",
                              ].join(" ")}
                              aria-label="Default select example"
                              as="select"
                              name="country"
                              onChange={(e) => {
                                handleChange(e);
                                getState(e.target.value);
                              }}
                            >
                              <option defaultValue value="">
                                Select
                              </option>
                              {countries.map((country, index) => (
                                <option value={country.isoCode} key={index}>
                                  {country.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label>
                              State<span>*</span>
                            </label>
                            <Field
                              className={[
                                commonStyles.formControl,
                                "form-select",
                              ].join(" ")}
                              aria-label="Default select example"
                              as="select"
                              name="country_state"
                            >
                              <option defaultValue value="">
                                Select
                              </option>
                              {stateByCountry.map((state, index) => (
                                <option value={state.name} key={index}>
                                  {state.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="country_state"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label>
                              GST license Number<span>*</span>
                            </label>
                            <Field
                              type="text"
                              name="gst_license_number"
                              className={commonStyles.formControl}
                              placeholder="Enter GST license Number"
                            />
                            <ErrorMessage
                              name="gst_license_number"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6}>
                            <label>
                              Address<span>*</span>
                            </label>
                            <Field
                              name="address"
                              type="text"
                              className={commonStyles.formControl}
                              placeholder="Enter Address"
                              aria-label="Enter full name"
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label>
                              Manager Name<span>*</span>
                            </label>
                            <Field
                              name="manager_name"
                              type="text"
                              className={commonStyles.formControl}
                              placeholder="Enter Manager Name"
                              aria-label="Enter full name"
                            />
                            <ErrorMessage
                              name="manager_name"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6}>
                            <label>
                              Employee Strength<span>*</span>
                            </label>
                            <Field
                              name="employee_strength"
                              type="text"
                              className={commonStyles.formControl}
                              placeholder="ex: 200"
                              aria-label="Enter full name"
                            />
                            <ErrorMessage
                              name="employee_strength"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label>
                              Revenue<span>*</span>
                            </label>
                            <Field
                              name="revenue"
                              type="text"
                              className={commonStyles.formControl}
                              placeholder="ex: 200"
                              aria-label="Enter full name"
                            />
                            <ErrorMessage
                              name="revenue"
                              component="div"
                              className="text-danger"
                            />
                            {/* <select
                              className={[
                                commonStyles.formControl,
                                "form-select",
                              ].join(" ")}
                              aria-label="Default select example"
                            >
                              <option selected>Select Revenue</option>
                              <option value="1">Select Revenue</option>
                              <option value="2">Select Revenue</option>
                              <option value="3">Select Revenue</option>
                            </select> */}
                          </Col>

                          <Col md={6}>
                            <label>
                              Type of the Legal Entity<span>*</span>
                            </label>
                            <Field
                              className={[
                                commonStyles.formControl,
                                "form-select",
                              ].join(" ")}
                              aria-label="Default select example"
                              as="select"
                              name="legal_entity"
                            >
                              <option defaultValue>Select</option>
                              <option value="Partnership">Partnership</option>
                              <option value="Sole proprietorship">
                                Sole proprietorship
                              </option>
                              <option value="Private Limited">
                                Private Limited
                              </option>
                            </Field>
                            <ErrorMessage
                              name="legal_entity"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <label>
                              GST frequency<span>*</span>
                            </label>
                            <div className="d-flex customFlex">
                              <label
                                className={[
                                  commonStyles.radioContainer,
                                  commonStyles.formControl,
                                ].join(" ")}
                              >
                                Monthly
                                <Field
                                  type="radio"
                                  name="gst_frequency"
                                  value="1"
                                />
                                <span className={commonStyles.checkmark}></span>
                              </label>
                              <label
                                className={[
                                  commonStyles.radioContainer,
                                  commonStyles.formControl,
                                ].join(" ")}
                              >
                                Quarterly
                                <Field
                                  type="radio"
                                  value="2"
                                  name="gst_frequency"
                                />
                                <span className={commonStyles.checkmark}></span>
                              </label>
                            </div>
                          </Col>

                          <Col md={6}>
                            <label>
                              Specified Domestic Transaction with Associated
                              Enterpris &gt; 20 Cr
                            </label>
                            <div className="d-flex customFlex">
                              <label
                                className={[
                                  commonStyles.radioContainer,
                                  commonStyles.formControl,
                                ].join(" ")}
                              >
                                Yes
                                <Field
                                  type="radio"
                                  value="1"
                                  name="domestic_transaction"
                                />
                                <span className={commonStyles.checkmark}></span>
                              </label>
                              <label
                                className={[
                                  commonStyles.radioContainer,
                                  commonStyles.formControl,
                                ].join(" ")}
                              >
                                No
                                <Field
                                  type="radio"
                                  value="2"
                                  name="domestic_transaction"
                                />
                                <span className={commonStyles.checkmark}></span>
                              </label>
                            </div>
                          </Col>
                          <Col md={12}>
                            <label>
                              International Transaction with Associated
                              Enterprise
                            </label>
                            <div className="d-flex customFlex">
                              <label
                                className={[
                                  commonStyles.radioContainer,
                                  commonStyles.formControl,
                                ].join(" ")}
                              >
                                Yes
                                <Field
                                  type="radio"
                                  value="1"
                                  name="international_transaction"
                                />
                                <span className={commonStyles.checkmark}></span>
                              </label>
                              <label
                                className={[
                                  commonStyles.radioContainer,
                                  commonStyles.formControl,
                                ].join(" ")}
                              >
                                No
                                <Field
                                  type="radio"
                                  value="2"
                                  name="international_transaction"
                                />
                                <span className={commonStyles.checkmark}></span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        {/* Add similar Field components for other input fields */}
                        <div
                          className={[
                            commonStyles.formGroup,
                            "text-center mar-b-0 d-flex justify-content-between",
                          ].join(" ")}
                        >
                          <Link
                            to="/company-setup"
                            value="BACK"
                            className={[
                              "btn btn-default",
                              commonStyles.back,
                            ].join(" ")}
                          >
                            <img src={backBtn} alt="Back" /> Back
                          </Link>

                          <div>
                            {userData &&
                            userData?.company_setup_details &&
                            userData?.company_setup_details.length > 0 ? (
                              <p
                                style={{ marginRight: "10px" }}
                                onClick={onSkip}
                                className={[
                                  "btn btn-default",
                                  commonStyles.back,
                                ].join(" ")}
                              >
                                {"Skip"}
                              </p>
                            ) : null}
                            <button type="submit" className={commonStyles.next}>
                              {isLoading ? (
                                <Spinner />
                              ) : userData?.company_type === 1 ? ( // 1 Indicate to single company type
                                "Finish"
                              ) : (
                                "Next"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompanySetup2;
