export const faqData = [
    {
      id: "headingOne",
      title: "What types of Financial & Compliance tasks can Acont automate?",
      desc: "Acont can automate Compliance Tracking, Financial Reporting including Financial Statement Generation, Risk Assessments, Workpaper Management, Audit and Sign off.",
    },
    {
      id: "headingTwo",
      title:
        "How does Acont demonstrate its value proposition for accountants and Auditing firms?",
      desc: "Acont helps accountants save time, improve accuracy, gain real-time insights, and mitigate risks, ultimately leading to better decision-making and enhanced client service",
    },
    {
      id: "headingThree",
      title: "How does Acont improve the accuracy of financial reporting?",
      desc: "Acont utilizes AI-powered automation and data validation checks to minimize errors and ensure data integrity.",
    },
    {
      id: "headingFour",
      title: "What kind of Real-Time insights does Acont provide?",
      desc: "Acont offers Real-Time dashboards and Key matrix on financial performance, compliance status, and potential risks, allowing for proactive decision-making.",
    },
    {
      id: "headingFive",
      title: "How much time can Acont save on financial reporting and audits?",
      desc: "Acont can save 70-75% of time spent on the financial reporting and audit timeframes by automating tasks and streamlining processes. The exact time saved will depend on the complexity of your financial operations",
    },
    {
      id: "headingSix",
      title: "What AI capabilities does Acont utilize?",
      desc: "Acont leverages AI for tasks such as anomaly detection, risk identification, Vouching, Data Mapping, and Intelligent data analysis, enhancing efficiency and accuracy.",
    },
    {
      id: "headingSeven",
      title: "Can Acont integrate with existing accounting software and systems?",
      desc: "Acont offers seamless integration with various accounting software and systems, ensuring smooth data flow and minimizing manual data import.",
    },
    {
      id: "headingEight",
      title:
        "What level of technical expertise is required to use Acont effectively?",
      desc: "Acont boasts a user-friendly interface and requires minimal technical expertise for setup and operation.",
    },
    {
      id: "headingNine",
      title:
        "What level of accounting expertise is required to use Acont effectively?",
      desc: "Acont is designed for accountants of all levels. While basic accounting knowledge is beneficial, the platform offers intuitive user interfaces and training resources to ensure smooth adoption.",
    },
    {
      id: "headingTen",
      title: "Does Acont offer training and support for users? ",
      desc: "Acont provides comprehensive training materials and ongoing support to ensure users can maximize the platform's capabilities.",
    },
    {
      id: "headingEleven",
      title:
        "What are the security measures in place to protect my financial data?",
      desc: "Acont utilizes industry-standard security protocols and data encryption to ensure the highest level of data security. The data will be stored in the Server located in India.",
    },
    {
      id: "headingTweleve",
      title: "What pricing plans does Acont offer?",
      desc: "Acont offers various pricing plans based on company size, features required, and number of users.",
    },
    {
      id: "headingThirteen",
      title: "Does Acont offer a free trial?",
      desc: "Yes, Acont offers a free trial for 2 weeks to allow potential users to experience the platform firsthand.",
    },
  ];