/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import commonStyles from "../../../common/commonStyles.module.css";
import styles from "./style.module.css";
const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <header className={[styles.headerOne, styles.headerSticky].join(" ")}>
          <Container>
            <Row className="py-3">
              <Col lg="12">
                <div className={styles.headerMainWrapper}>
                  <a href="/" className="logo">
                    <img src={logo} alt="logo" />
                  </a>
                  {/* <div className={styles.navAreaMain} id="side-bar">
                    <nav>
                      <ul>
                        <li>
                          <a className={styles.linksMain} href="/">
                            Home
                          </a>
                        </li>

                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Product <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Product Details</a>
                            </li>
                            <li>
                              <a href="#">Favorite Product</a>
                            </li>
                            <li>
                              <a href="#">Checkout Product</a>
                            </li>
                            <li>
                              <a href="#">All Products</a>
                            </li>
                          </ul>
                        </li>
                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Solutions <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Solutions Details</a>
                            </li>
                            <li>
                              <a href="#">Solutions</a>
                            </li>
                            <li>
                              <a href="#">Solutions</a>
                            </li>
                            <li>
                              <a href="#">All Solutions</a>
                            </li>
                          </ul>
                        </li>
                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Resources <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Resources Details</a>
                            </li>
                            <li>
                              <a href="#">Resources</a>
                            </li>
                            <li>
                              <a href="#">Resources</a>
                            </li>
                            <li>
                              <a href="#">All Resources</a>
                            </li>
                          </ul>
                        </li>
                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Company <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Company Details</a>
                            </li>
                            <li>
                              <a href="#">Company</a>
                            </li>
                            <li>
                              <a href="#">Company</a>
                            </li>
                            <li>
                              <a href="#">All Company</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div> */}
                  <div className={styles.buttonArea}>
                    {/* <Link
                      to="/login"
                      className={[
                        styles.rtsBtn,
                        styles.btnLonly,
                        styles.mr30,
                      ].join(" ")}
                    >
                      Sign in
                    </Link> */}
                    <Link
                      to="/login"
                      className={[styles.rtsBtn, styles.btnBlur].join(" ")}
                    >
                      Get Started
                    </Link>
                  </div>

                  {/* <div className={styles.menuBtn} id="menuBtn">
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="14" width="20" height="2" fill="#1F1F25"></rect>
                      <rect y="7" width="20" height="2" fill="#1F1F25"></rect>
                      <rect width="20" height="2" fill="#1F1F25"></rect>
                    </svg>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      </div>

  <section className={commonStyles.cmsData}>
      <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Terms and Conditions</h2>
              <p>Please read the terms contained in this agreement (“Terms of Use”, “User Agreement” or “Agreement”) carefully before using Acontway Technologies Pvt Ltd. (hereinafter referred to as “Website” or “Site”) or its mobile application (hereinafter referred to as “Application” or “App”). This Agreement sets out legally binding terms of use between the user of this site and Acontway Technologies Pvt Ltd. LLC the “Company” that monitors and has ownership rights to the Site and App. By accessing and/or using the Site and the App the user acknowledges that he/she has read understood and agree to be legally bound by the terms and conditions set forth in this document. If you do not agree to abide by this User Agreement, you are not permitted to access or use the Site / App. All text, graphics, design, content, and other works are copyrighted works of Company. The Company acknowledges proprietary rights of third parties displayed on the Site and App.  </p>
              <p>For purpose of this Agreement the terms “we”, “us” or “our”, refer to the Company, Site and App, used interchangeably. When you use the service available on the Site or the App, you consent to our collection, use, and disclosure of information about you as described in this Agreement.   </p>
              <p>This Agreement is effective from 3/22/2020 and continues until revoked by the Company.</p>
              <h4>1. YOUR PRIVACY IS IMPORTANT TO US</h4>
              <p>1.1 Your privacy is important to you and to us. We’ll protect the information you share with us. To protect your privacy, the Site follows different principles in accordance with worldwide practices for customer privacy and data protection.</p>
              <p>1.2 We at the Company are committed to respecting your online privacy and recognize your need for appropriate protection and management of any personally identifiable information (“Personal Information”) you share with us. For purpose of this Policy, Personal Information means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, whether at work or home. In general, you can visit the Site’s web pages without telling us who you are or revealing any Personal Information about yourself. If however, Personal Information is revealed to us, we won’t</p>
              <p>1‘User’ shall have the same meaning as defined under Section 2 (1) (j) of the Information Technology (Intermediaries Guidelines Rules, 2011). Per the said Rules, user means any person who accesses or avails any computer recourse of intermediary for the purpose of hosting, publishing, sharing, transacting, displaying or uploading information or views and includes other persons jointly participating in using the computer recourses of an intermediary.</p>
              <p>sell or give away any content of your Personal Information to anyone except people who may be involved in the delivery of Service.  </p>
              <p>2. information we collect and how we use it</p>
              <p>2.1 When you visit the Site or use our Service, we collect and store information about you, your computer or mobile device and your activities. This information may include, but is not limited to:</p>
              <p>2.2.1 Your computer’s IP address; technical information about your computer or mobile device (such as type of device, web browser or operating system).</p>
              <p>2.2.2 Your mobile device’s unique ID number (when available), your mobile device’s geographic location (specific geographic location if you’ve enabled collection of that information, or general geographic location automatically).</p>
              <p>2.2.3 Your provided full name, email address, zip code and other information you may provide with your account, such as your gender and birth date. You may optionally provide us with this information through third party sign-in services such as Facebook and Google Plus. In such cases, we fetch and store whatever information is made available to us by you through these sign-in services.</p>
              <p>2.2.4 How long you visited our service and which features you used.</p>
              <p>2.2.5 We gather information from members and guests who apply for the various Services that our site offers. It includes, but may not be limited to, email address, first name, last name, a user-specified password, e-mail Id, mailing address, zip code and telephone number or fax number. We collect information primarily to ensure that we are able to fulfill your requirements and to deliver personalized experience.</p>
              <h4>3. ELIGIBILITY</h4>
              <p>3.1 To register as a member of the Site, you must be of sound mind. By using this Site, you represent and warrant that you have the right, authority, and legal capacity to enter into this Agreement and that you are not prohibited or prevented by any applicable law for the time being in force or any order or decree or injunction from any court, tribunal or any such competent authority restraining you from availing our Services. You also agree to abide by all of the terms and conditions of this Agreement. If at any time the Company is of the opinion (in its sole discretion) or has any reason to believe that you are not eligible to become a member or that you have made any misrepresentation about your eligibility, the Company reserves the right to forthwith terminate your subscription and / or your right to use the Service, without any refund to you, for any of your unutilized subscription fee, if any.</p>
              <p>3.2 User and Service Provider represents that they are not minors (16 or above) and are not persons with any criminal record nor barred by the government from receiving any services under any law in India. User and service provider agree to the following:</p>
              <p>• Provide accurate, current, true and complete information about them while registering on our Website or App.</p>
              <p>• Maintain and promptly update your profile and registration data to keep it accurate, true, current and complete.</p>
              <p>3.3 Under an event of information being found incomplete, false or inaccurate, we reserve the right to delete, terminate or deactivate your account without any notification or intimation and refuse any current or future use of our Website and/or App.</p>
              <p>3.4 When you register on our Website and/or App, you will be required to choose a username and a password. You are responsible for maintaining the confidentiality of your password and account information. You must immediately notify us of any unauthorized use of password or account or any other security breach.</p>
              <p>3.5 You are liable for maintaining the confidentiality of any login information associated with any account you use to access the Services or resources, and thus you are also responsible for all activities that occur on your account. You will be solely responsible for any consequences, losses, or damages that the Company may directly or indirectly incur or suffer due to any illegal or unauthorized activities conducted by you or person engaged by you.</p>
              <h4>4. PROPRIETARY RIGHTS AND TRADEMARKS</h4>
              <p>4.1 The Site contains copyright material, trademark and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music and sound. All proprietary material displayed on the Site or provided on demand, is copyrighted as a collective work either owned by the Company or licensed from a third party.</p>
              <p>4.2 The Company owns copyright in the selection, coordination, arrangement and enhancement of such proprietary material. User may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit the content, in whole or in part. User may download copyrighted material for user’s personal use only. Except as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material will be permitted without the express permission of the Company. In the event of any permitted copying, redistribution or publication of copyrighted material, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made. User acknowledges that it does not acquire any ownership rights by downloading copyrighted material.</p>
              <h4>5. DISCLAIMER AND LIABILITY</h4>
              <p>5.1 User and service provider expressly agree that use of Services is at their sole risk. Neither the Company its affiliates nor any of its respective employees, agents, service providers, third party agents warrant that the Company’s Services will be uninterrupted, faulty or error free. The Company makes no representations about the accuracy of the information contained in the material provided and graphics on this Website for any purpose. The Company hereby disclaims all warranties and conditions with regard to this information, including all implied warranties and conditions of merchantability, fitness for any particular purpose, title and non-infringement. In no event, shall the Company be liable to any party for any direct, indirect, special or other consequential damages for any use of the Services, the information, or on any other hyperlinked web site, including, without limitation, any lost profits, business interruption, loss of programs or other data on your information handling system or otherwise, even if the Company is expressly advised of the possibility of such damages. The Company is not responsible for any incorrect or inaccurate content provided to the user or service provider whether on the Site or otherwise. The user and service provider must use its discretion before availing Services of the Company.</p>
              <p>5.2 User and service providers agree and assure to not hold the Company, its employees, agents, directors, officer bearers, managers, investors, donors, and licensors responsible, for and against all losses, whether financial, intellectual, mental, physical (including misuse of debit / credit card and online banking facilities) or otherwise, expenses, damages and costs, including reasonable attorneys’ fees, resulting from use of the Site or Services. Despite regular controls the Company is not liable for any mishap or misuse of financial or personal information of the service provider. We reserve the right to be indemnified for the above mentioned. In such event, user shall provide us with such cooperation as is reasonably requested by us.</p>
              <p>5.3 The Company is not responsible for any problems or technical malfunction of any telephone network or lines, computer on-line-systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users and Subscribers or to any other person’s computer related to or resulting and/or in connection with the Company’s service. The Company expressly disclaims any liability or responsibility whatsoever and howsoever arising as a result of any content of study material made available to subscribers.</p>
              <p>5.4 The Company does not warrant or guarantee that the information contained on the Site is accurate or complete, and hereby disclaims any and all liability to any person for any loss or damage caused by errors or omission, whether such errors or omissions result from negligence, accident or any other cause. Before availing Services, user discretion is advised. The Company further assumes no liability for the interpretation and/or use of the information contained on this Site, nor does it offer a warranty of any kind, either expressed or implied. Also, we do not guarantee that the use of our Services, resources and/or information provided on the Site will meet your needs or requirements. We do not also guarantee that the information obtained by using our Services will be accurate.</p>
              <p>5.5 The Company makes no commitment to update the information or materials on this Website which, as a result, may be out of date. Neither us, nor our officers, directors, employees, agents or affiliates are responsible or liable for any loss damage (including, but not limited to, actual, consequential, or punitive), liability, claim, or other injury or cause related to or resulting from any information provided by us. The Company reserves the right to revise these terms at any time. User is responsible for reviewing this page from time to time to ensure compliance with the then current terms and legal restrictions because they will be binding upon them.  </p>
              <p>5.6 Force majeure: the Company will not be responsible for any failure or delay in performance due to circumstances beyond its reasonable control, including, without limitation, acts of god, war, riot, embargoes, acts of civil or military authorities, fire, floods, accidents, service outages resulting from equipment and/or software failure and/or telecommunications failures, power failures, network failures, failures of third party service providers (including providers of internet services and telecommunications). The party affected by any such event shall notify the Company within a maximum of fifteen (15) days from its occurrence. The performance of this Agreement shall then be suspended for as long as any such event shall prevent the affected party from performing its obligations under this Agreement.</p>
              <h4>6. INDEMNIFICATION</h4>
              <p>6.1 User and service provider shall be responsible for obtaining and maintaining all telephone, computer hardware, software and other equipment needed for access to and use of Website and all charges related thereto shall be borne by the concerned parties.</p>
              <p>6.2 The Company will not be liable and you agree not to claim for any direct, indirect, incidental, exemplary or consequential loss or damages which may be incurred by user or service provider as a result of using our Services, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent, and that in such conditions limitation of liability laws and clause apply.</p>
              <h4>7. Disclaimer of warranty and limitation of liability</h4>
              <p>7.1 Users expressly agree that use of the Website / App is being used at his / her sole risk. Neither us nor our affiliates, employees, directors, partners, agents, third party content providers or licensors warrant that the Website / App will be uninterrupted or error free; nor do they make any warranty as to the results that may be obtained from use of Website / App, or as to the accuracy, reliability or content of any information, service, or merchandise provided through the Website / App.</p>
              <p>7.2 The Website / App is provided on an “as is” basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to this Agreement.</p>
              <p>7.3 Notice of liability: despite regular controls we are not liable for any negligence at our hands, should one occur. The said user is himself fully responsible for his / her respective usage.. Please contact us if you notice pages with illegal or immoral content. The legal information in this Agreement also applies to our social media outlets, such as Facebook, Google+, Twitter, LinkedIn and the like.</p>
              <p>7.4 This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortuous behavior, negligence, or under any other cause of action. User specifically acknowledges that we are not liable for defamatory, offensive or illegal conduct of other users or third-parties and that the risk of injury from the foregoing rests entirely with user.  </p>
            <p>7.5 In no event will we or any person or entity involved in creating the Website and Mobile Application be liable for any damages, including, without limitation, direct, indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use Website / App. User hereby acknowledges that the provisions of this section shall apply to all content on the Site and our Mobile Application.</p>
            <p>7.6 In addition to the terms set forth above neither we nor our affiliates, suppliers or vendors shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness or unauthenticity of the information contained within the Website / App, or for any delay or interruption in the transmission thereof to the user, or for any claims or losses arising there from or occasioned thereby. None of the foregoing parties shall be liable for any third-party claims or losses of any nature, including, but not limited to, lost profits, punitive or consequential damages.  </p>
            <p>7.7 This User Agreement template is not legal advice and by using them you agree to this disclaimer. The content of this document is for informational purposes only and does not constitute advertising, a solicitation or legal advice. Its recommended that you should take independent legal advice before publishing this agreement on your site or mobile application. You should read the information with care and modify, delete or add all and any areas as necessary. Use of, access to or transmission of such materials and information or any of the links contained herein is not intended to create, and receipt thereof does not constitute formation of, an attorney-client relationship between us and the user. The information contained is provided only as general information and may or may not reflect the most current legal developments; accordingly, information is not promised or guaranteed to be correct or complete. We expressly disclaim all liability in respect to any actions taken or not taken based on any or all of the contents of this content.</p>
            <p>7.8 Disclaimer of Content: Every effort has been made to ensure that the information contained in this Site / App is accurate and true. The content of the Site / App is believed to be correct at the time of compilation. However, the Company makes no representations or warranties about the content and suitability of the information contained herein for any purpose. The Company, its servants, agents and employees disclaim all liability for the accuracy, completeness, or usefulness of any information, apparatus, products or process disclosed and for any error or omission therein.</p>
            <h4>9. Restrictions</h4>
            <p>9.1  You may view, download and copy information and materials available on this Website solely for your personal, non-commercial use. Even though the Company does everything in its hand to protect both your information and financial transaction details, the Site is not liable for any fraud, theft or financial misdemeanor that may occur as a result of your financial transactions on out Site.</p>
            <h4>10. MEMBER ACCOUNT, PASSWORD, AND SECURITY</h4>
            <p>10.1 Since the Services require user and service provider to open an account, you must complete the registration process by providing the Company with current, complete and accurate information as prompted by the applicable registration form. You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify the Company immediately of any unauthorized use of your account or any other breach of security. The Company will not be liable for any loss that user may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by the Company or another party due to someone else using user’s account or password.</p>
            <h4>11. PAYMENT SECURITY</h4>
            <p>11.1 The Site has taken strong measures to protect the security of your personal information and to ensure that your choices for its intended use are honoured. We take strong precautions to protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction. You also have a significant role in protecting your information. No one can see or edit your personal information without knowing your user name and password, so do not share these with others.</p>
            <p>11.2 Since this is an intermediary and an ‘information only site’, we do not guarantee your e-commerce transactions to be entirely safe and secure. When you place orders through our website or online payment gateway service providers (“OPGSPs”), listed on the Site, you must ensure that you agree to the terms and conditions of the said OPGSPs agreement. The Company shall not be liable should there be a discrepancy in refund, loss or misuse of credit card details, hacking of sensitive monetary information.</p>
            <p>11.3 Resolution of all payment related complaints shall remain the responsibility of OPGSPs concerned. Users must read and agree to privacy policy and online security framework of respective OPGSPs listed on the Site before transacting. We do not take responsibility for any security lapse, should infringement or violation happen as a result of you accessing an OPGSP listed on the Site.</p>
            <h4>12. Feedback  </h4>
            <p>12.1  All comments, feedback, information or materials submitted to the Company through or in association with our Website shall be considered non-confidential and Company’s property. By submitting such comments, information, feedback, or materials to us, you agree to a no-charge assignment to the Company of worldwide rights to use, copy, modify, display and distribute the submissions. The Company may use such comments, information or materials in any way it chooses in an unrestricted basis. Users are welcome to provide candid feedback to us.</p>
            <h4>13. Cookie Policy</h4>
            <p>When you visit our Site, we may place “cookies” on your computer to recognize you. We do this to store your preferences, collect statistical data and track your use of our Service. Cookies are widely used, and most web browsers are configured initially to accept cookies automatically. If you prefer not to accept cookies, you may adjust and configure your browser settings accordingly. However, if you turn off cookies, or refuse to accept a request to place a cookie, some features and services on the Site may not function properly. Ad companies (including but not limited to Google and Facebook) may also place cookies on your computer to track your activities across various sites so that they can display targeted advertisements.</p>
            <h4>14. General Provisions and termination</h4>
            <p>14.1 If any provision of this Agreement is deemed void, unlawful or otherwise unenforceable for any reason, that provision shall be severed from this Agreement and the remaining provisions of this Agreement shall remain in force. This contains the entire agreement between you and the Company concerning your use of the Site.</p>
            <p>14.2 You agree that we may, at our sole discretion, permanently or temporarily suspend or terminate your access to all or parts of the Site and Services with or without notice and for any reason, including, without limitation, breach of this User Agreement, and/or any suspected illegal and/or fraudulent or abusive and/or competitive activity may be grounds for terminating your access and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Site will immediately cease.  </p>
            <p>IN WITNESS WHEREOF, all users, visitors and service provide agree that they have read this document and agrees to be bound by them for as long as they continue to either use the Site or avail Services of the Company.</p>
              </div>
          </div>
      </div>
  </section>
      <footer>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className={commonStyles.footerLinks}>
                <a href="/" className="logo">
                  <img src={logo} alt="logo" />
                </a>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">FAQ</a>
                  </li>
                  <li>
                    <a href="#">Help</a>
                  </li>
                  {/* <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col md={6} className="text-end">
              <form>
                <div className={commonStyles.formGroup}>
                  <input type="text" placeholder="Email Address" />
                  <button
                    style={{ marginLeft: "8px" }}
                    onClick={() => navigate("/login")}
                  >
                    Get Started
                  </button>
                </div>
              </form>
            </Col>
          </Row>
          <hr />
          <Row className={commonStyles.copyright}>
            <Col md={6}>
              <div className={commonStyles.copyrightLink}>
                <p>
                Copyright © 2024 Acontway Technologies Pvt Ltd. - All Rights Reserved.
                </p>
              </div>
            </Col>
            <Col md={6} className="text-end">
              <ul>
              <li>
                  <a href="/terms-conditions">Terms</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy</a>
                </li>
                
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className={styles.progressWrap}>
        <svg
          className={[styles.progressCircle, "svg-content"].join(" ")}
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            // style="
            //   transition: stroke-dashoffset 10ms linear 0s;
            //   stroke-dasharray: 307.919, 307.919;
            //   stroke-dashoffset: 307.919;
            // "
          ></path>
        </svg>
        <div className={styles.arrowUp}>
          <img src="" alt="" />
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
