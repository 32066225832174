const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem("token"));
  },
  setToken: (token) => {
    window.localStorage.setItem("token", JSON.stringify(token));
  },
  getUser: () => {
    return JSON.parse(window.localStorage.getItem("user"));
  },
  setUser: (User) => {
    window.localStorage.setItem("user", JSON.stringify(User));
  },
  clearToken: () => {
    window.localStorage.removeItem("token");
  },
};

export default storage;
