import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddMoreLocation from "../pages/Auth/AddMoreLocation";
import CompanyDetails from "../pages/Auth/CompanyDetails";
import CompanyDetailsEdit from "../pages/Auth/CompanyDetailsEdit";
import CompanySetup from "../pages/Auth/CompanySetup";
import CompanySetup2 from "../pages/Auth/CompanySetup2";
import Login from "../pages/Auth/Login";
import Otp from "../pages/Auth/Otp";
import Register from "../pages/Auth/Register";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/Cms/privacy/index";
import TermsAndConditions from "../pages/Cms/terms/index";

const MainRoutes = () => {
  return (
    <>
      <ToastContainer
        position="top-right" // Position of the toast container
        autoClose={5000} // Time in milliseconds after which the toast will automatically close
        hideProgressBar={false} // Whether to hide the progress bar
        newestOnTop={false} // Whether new toasts should appear on top
        closeOnClick // Whether to close the toast when clicked
        rtl={false} // Whether to display the toast from right to left
        pauseOnFocusLoss // Whether to pause the timer when the focus is lost
        draggable // Whether to allow dragging to dismiss the toast
        pauseOnHover // Whether to pause the timer when hovered
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/otp" element={<Otp />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/company-setup" element={<CompanySetup />} />
        <Route
          exact
          path="/company-setup-details"
          element={<CompanySetup2 />}
        />
        <Route exact path="/company-details" element={<CompanyDetails />} />
        <Route
          exact
          path="/company-details-edit/:index"
          element={<CompanyDetailsEdit />}
        />
        <Route exact path="/add-more-location" element={<AddMoreLocation />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-conditions" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
