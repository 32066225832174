import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import commonStyles from "../../../common/commonStyles.module.css";
import Spinner from "../../../components/Spinner";
import { setUser } from "../../../redux/slices/userSlice";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { handleSuccess } from "../../../utils/helperFunction";
import { CompanyDetail } from "../CompanySetup2/api";
import { registerValidationSchema } from "./validations";

/**
 * Register component for user registration.
 * @returns {JSX.Element} Register component JSX
 */

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetching user data from Redux store
  const userData = useSelector((state) => state.user.data);
  const [isLoading, setLoading] = useState(false);

  // Initial form values based on user data from Redux store
  const initialValues = {
    name: userData?.name || "",
    category: userData?.category || "",
    designation: userData?.designation || "",
    phone_number: userData?.phone_number || "",
  };

  /**
   * Function to handle form submission.
   * @param {object} values - Form values
   */
  const onSubmit = (values) => {
    let apiData = { ...userData, ...values };
    dispatch(setUser(apiData));

    if (values.category === "2") {
      registerUser(apiData);
      return;
    }
    navigate(RoutesUrl.COMPANY_SETUP);
  };

  /**
   * Function to register user with company details.
   * @param {object} apiData - User data
   */
  const registerUser = async (apiData) => {
    setLoading(true);
    try {
      const res = await CompanyDetail(apiData);
      setLoading(false);
      if (res && res.success) {
        handleSuccess(navigate, dispatch); // showing success popup
      }
    } catch (err) {
      setLoading(false);
      console.error("Error sending email:", err);
      toast.error(err?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={[commonStyles.authOutter, commonStyles.registerOutter].join(" ")}
    >
      <Container>
        <Row>
          <Col md={12}>
            <div className={commonStyles.registerCard}>
              <div className={commonStyles.formWizard}>
                <div className="myContainer">
                  <div
                    className={[commonStyles.formContainer, "animated"].join(
                      " "
                    )}
                  >
                    <h2 className={commonStyles.formTitle}>
                      Please fill in the details below
                    </h2>
                    <hr />
                    <Formik
                      initialValues={initialValues}
                      validationSchema={registerValidationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form className={commonStyles.belowForm}>
                          <Row className="form-lb">
                            <Col md={6}>
                              <label>
                                Full Name<span>*</span>
                              </label>
                              <Field
                                type="text"
                                name="name"
                                className={commonStyles.formControl}
                                placeholder="Enter full name"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col md={6}>
                              <label>
                                Phone No.<span>*</span>
                              </label>
                              <Field
                                type="text"
                                name="phone_number"
                                className={commonStyles.formControl}
                                placeholder="Phone Number"
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <label>
                                Designation<span>*</span>
                              </label>
                              <Field
                                as="select"
                                name="designation"
                                className={[
                                  commonStyles.formControl,
                                  "form-select",
                                ].join(" ")}
                              >
                                <option value="">Select designation</option>
                                <option value="CEO">CEO</option>
                                <option value="Manager">Manager</option>
                                <option value="CTO">CTO</option>
                              </Field>
                              <ErrorMessage
                                name="designation"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col md={6} className="flex-m">
                              <label>
                                Category<span>*</span>
                              </label>
                              <div className="d-flex">
                                <label
                                  className={[
                                    commonStyles.radioContainer,
                                    commonStyles.formControl,
                                  ].join(" ")}
                                >
                                  Business Firm
                                  <Field
                                    type="radio"
                                    name="category"
                                    value="1"
                                  />
                                  <span
                                    className={commonStyles.checkmark}
                                  ></span>
                                </label>
                                <label
                                  className={[
                                    commonStyles.radioContainer,
                                    commonStyles.formControl,
                                  ].join(" ")}
                                >
                                  Audit Firm
                                  <Field
                                    type="radio"
                                    name="category"
                                    value="2"
                                  />
                                  <span
                                    className={commonStyles.checkmark}
                                  ></span>
                                </label>
                              </div>
                              <ErrorMessage
                                name="category"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} className="text-end">
                              <button
                                type="submit"
                                className="form-group text-end customCta"
                              >
                                {isLoading ? <Spinner /> : "Next"}
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Register;
