/* eslint-disable jsx-a11y/anchor-is-valid */
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pattern from "../../assets/images/Pattern.svg";
import bannerCheck from "../../assets/images/banner-check.png";
import dashboardMap from "../../assets/images/dashboard_map2.png";
import downArrow from "../../assets/images/down-arrow.png";
import featureImg3 from "../../assets/images/feature-img-3.png";
import featureImg4 from "../../assets/images/feature-img-4.png";
import featureImg5 from "../../assets/images/feature-img-5.png";
import featureImg6 from "../../assets/images/feature-img-6.png";
import logo from "../../assets/images/logo.png";
import modalCross from "../../assets/images/modalCross.png";
import purpleCheck from "../../assets/images/purple_Check.png";
import modalCheck from "../../assets/images/thankyou.png";
import upArrow from "../../assets/images/up-arrow1.png";
import commonStyles from "../../common/commonStyles.module.css";
import Spinner from "../../components/Spinner";
import { SubscriptionDetail } from "./api";
import { faqData } from "./faqData";
import styles from "./style.module.css";
import { validateEmail } from "./validations";

const Home = () => {
  const [show, setShow] = useState(false);
  const [showRegisteredModal, setShowRegisteredModal] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const handleClose = () => setShow(false);
  const handleCloseRegisteredModal = () => setShowRegisteredModal(false);
  const [value, setValue] = useState("1");
  const [faqSelected, setFaqSelected] = useState("headingOne");
  const [isLoading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailEmptyError, setEmailEmptyError] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [active, setActive] = useState("statutory");
  const navigate = useNavigate();

  const TextRotator = () => {
    const phrases = [
      "Comprehensive Compliance Management",
      "Robust Financial Reporting",
      "Seamless Auditing",
    ];
    const period = 5000;
    const [index, setIndex] = useState(0);
    const [currentPhrase, setCurrentPhrase] = useState("");
    const currentText = phrases[index];
    const typingSpeed = period / currentText.length;

    useEffect(() => {
      let currentCharacter = 0;
      const interval = setInterval(() => {
        if (currentCharacter <= currentText.length) {
          setCurrentPhrase(currentText.slice(0, currentCharacter));
          currentCharacter++;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
          }, 1000);
        }
      }, typingSpeed);
      return () => clearInterval(interval);
    }, [currentText, index, phrases.length, typingSpeed]);

    return <span className="text-rotate">{currentPhrase}</span>;
  };

  const onClickFaqHeader = (id) => {
    if (id === faqSelected) {
      setFaqSelected("");
    } else {
      setFaqSelected(id);
    }
  };

  const handleSubscribe = async () => {
    if(!subscribeEmail){
      setEmailEmptyError(true)
      return;
    }
    setEmailEmptyError(false)
    let error = validateEmail(subscribeEmail);

    if (error) {
      setLoading(true);
      setEmailError(false);
      try {
        const res = await SubscriptionDetail({ email: subscribeEmail });
        setLoading(false);
        if (res && res.status) {
          setShow(true);
        }
      } catch (err) {
        setLoading(false);
        if (err.data.message === "Email is required") {
          toast.error(err.data.message);
        } else {
          console.error("Error in subscribing:", err);
          setShowRegisteredModal(true);
        }
      }
    } else {
      setEmailError(true);
    }
  };

  return (
    <>
      <div>
        <header className={[styles.headerOne, styles.headerSticky].join(" ")}>
          <Container>
            <Row className="py-3">
              <Col lg="12">
                <div className={styles.headerMainWrapper}>
                  <a href="#" className="logo">
                    <img src={logo} alt="logo" />
                  </a>
                  {/* <div className={styles.navAreaMain} id="side-bar">
                    <nav>
                      <ul>
                        <li>
                          <a className={styles.linksMain} href="/">
                            Home
                          </a>
                        </li>

                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Product <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Product Details</a>
                            </li>
                            <li>
                              <a href="#">Favorite Product</a>
                            </li>
                            <li>
                              <a href="#">Checkout Product</a>
                            </li>
                            <li>
                              <a href="#">All Products</a>
                            </li>
                          </ul>
                        </li>
                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Solutions <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Solutions Details</a>
                            </li>
                            <li>
                              <a href="#">Solutions</a>
                            </li>
                            <li>
                              <a href="#">Solutions</a>
                            </li>
                            <li>
                              <a href="#">All Solutions</a>
                            </li>
                          </ul>
                        </li>
                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Resources <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Resources Details</a>
                            </li>
                            <li>
                              <a href="#">Resources</a>
                            </li>
                            <li>
                              <a href="#">Resources</a>
                            </li>
                            <li>
                              <a href="#">All Resources</a>
                            </li>
                          </ul>
                        </li>
                        <li className={styles.hasDropdown}>
                          <a className={styles.linksMain} href="#">
                            Company <FontAwesomeIcon icon={faChevronDown} />
                          </a>
                          <ul className={styles.submenu}>
                            <li>
                              <a href="#">Company Details</a>
                            </li>
                            <li>
                              <a href="#">Company</a>
                            </li>
                            <li>
                              <a href="#">Company</a>
                            </li>
                            <li>
                              <a href="#">All Company</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div> */}
                  <div className={styles.buttonArea}>
                    {/* <Link
                      to="/login"
                      className={[
                        styles.rtsBtn,
                        styles.btnLonly,
                        styles.mr30,
                      ].join(" ")}
                    >
                      Sign in
                    </Link> */}
                    <Link
                      to="/login"
                      className={[styles.rtsBtn, styles.btnBlur].join(" ")}
                    >
                      Get Started
                    </Link>
                  </div>

                  {/* <div className={styles.menuBtn} id="menuBtn">
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="14" width="20" height="2" fill="#1F1F25"></rect>
                      <rect y="7" width="20" height="2" fill="#1F1F25"></rect>
                      <rect width="20" height="2" fill="#1F1F25"></rect>
                    </svg>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      </div>
      <div
        className={[
          styles.rtsBannerAreaOne,
          styles.bgImage,
          styles.bgImage1,
          styles.rtsSectionGap,
        ].join(" ")}
      >
        <Container>
          <Row>
            <Col lg="12">
              <div className={styles.contentMainWrapper}>
                <h1 className={styles.title}>
                  Intuitive Platform for
                  <br />
                  <TextRotator />
                </h1>
                <p className={styles.disc}>
                  Embark on a journey into the future to effortlessly manage
                  compliances, Streamline Financial Reporting and Boost the
                  effectiveness of your audits with the power of AI.
                </p>
                <div className={styles.buttonArea}>
                  <Link
                    to="/login"
                    className={[styles.rtsBtn, styles.btnPrimary].join(" ")}
                  >
                    Get Started
                  </Link>
                </div>
                <div className={styles.bannerImg}>
                  <img src={dashboardMap} alt="" />
                </div>
                {/* <div className={styles.userArea}>
                  <p>10,000+ People make by OpenUp</p>
                  <div className={styles.imageWrapper}>
                    <img
                      className={styles.one}
                      src="assets/images/banner/user/01.png"
                      alt="user"
                      loading="lazy"
                    />
                    <img
                      className={styles.two}
                      src="assets/images/banner/user/02.png"
                      alt="user"
                      loading="lazy"
                    />
                    <img
                      className={styles.three}
                      src="assets/images/banner/user/04.png"
                      alt="user"
                      loading="lazy"
                    />
                    <img
                      className={styles.four}
                      src="assets/images/banner/user/03.png"
                      alt="user"
                      loading="lazy"
                    />
                    <img
                      className={styles.five}
                      src="assets/images/banner/user/05.png"
                      alt="user"
                      loading="lazy"
                    />
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>

        <div className={styles.usefullForAni}>
          <div className={styles.single}>
            <img src={bannerCheck} alt="img" loading="lazy" />
            <span>Audit</span>
          </div>
          <div className={[styles.single, styles.blog].join(" ")}>
            <img src={bannerCheck} alt="img" loading="lazy" />
            <span>Compliance</span>
          </div>
          <div className={[styles.single, styles.copy].join(" ")}>
            <img src={bannerCheck} alt="img" loading="lazy" />
            <span>
              Financial <br />
              Reporting{" "}
            </span>
          </div>
          <div className={[styles.single, styles.freelancer].join(" ")}>
            <img src={bannerCheck} alt="img" loading="lazy" />
            <span>
              Core <br />
              Accounting{" "}
            </span>
          </div>
        </div>
      </div>
      <section className={styles.featureArea}>
        <Container>
          <Row>
            <Col lg="12">
              <div className={styles.titleConterArea}>
                <span>Features</span>
                <h2 className={styles.title}>The Acont Platform</h2>
                <p className={styles.disc}>
                  Accelerate your Accounts and Audit team's productivity with
                  Intelligent Automation Platform
                </p>
              </div>
            </Col>
          </Row>
          <Row className={styles.mt64}>
            <Col lg="12">
              <div
                className={[
                  styles.singleFeatureAreaStart,
                  styles.indexFeature,
                  styles.columnReverse,
                ].join(" ")}
              >
                <div className={styles.imageArea}>
                  <img src={featureImg4} alt="feature_image" loading="lazy" />
                </div>
                <div className={styles.featureContentArea}>
                  <h2 className={styles.title}>Compliance Management</h2>
                  <p className={styles.disc}>
                    Our Platform simplify the 3 W's of Compliances for you (i.e.
                    What, Why & When ) so that you never miss a Compliance.
                  </p>
                  <ul>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Centralized Dashboard: </strong>Manage all your
                        Compliances from Single Dashboard
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Mitigate Risk: </strong>Prevent potential
                        penalties & litigation risk
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Real Time Updates: </strong>Stay updated with
                        frequent regulatory changes
                      </p>
                    </li>
                  </ul>
                  <a
                    href="#"
                    className={[
                      styles.mainBtn,
                      styles.rtsBtn,
                      styles.btnPrimary,
                      "mt-4",
                    ].join(" ")}
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div
                className={[
                  styles.singleFeatureAreaStart,
                  styles.indexFeature,
                  styles.bgRedL,
                ].join(" ")}
              >
                <div className={styles.featureContentArea}>
                  <h2 className={styles.title}>Financial Reporting</h2>
                  <p className={styles.disc}>
                    Our Platform automates your Financial Reporting process,
                    enabling you to dedicate less time to data manipulation &
                    more time to analysis.
                  </p>
                  <div className={commonStyles.tabsOutter}>
                    <div className={styles.content}>
                      <div className={commonStyles.contentInner}>
                        <div className={commonStyles.tabs}>
                          <div className={commonStyles.tabsNav}>
                            <div id="LabTabs">
                              <Box sx={{ width: "100%", typography: "body1" }}>
                                <TabContext value={value}>
                                  <Box
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <TabList
                                      onChange={handleChange}
                                      aria-label="lab API tabs example"
                                    >
                                      <Tab
                                        label="Statutory Reporting"
                                        value="1"
                                      />
                                      <Tab
                                        label="Management Reporting"
                                        value="2"
                                      />
                                    </TabList>
                                  </Box>
                                  <TabPanel
                                    value="1"
                                    className={commonStyles.blackSpace}
                                  >
                                    <ul>
                                      <li>
                                        <img src={purpleCheck} alt="" />
                                        <p>
                                          <strong>
                                            Multi GAAP Financial Reporting:
                                          </strong>
                                          Prepare the Financial Statements in
                                          the IndAS or Local GAAP.
                                        </p>
                                      </li>
                                      <li>
                                        <img src={purpleCheck} alt="" />
                                        <p>
                                          <strong>
                                            Ready to Sign Financials Reports:
                                          </strong>
                                          Financial Statements configured in
                                          line with the Schedule III of
                                          Companies Act, 2013 along with the
                                          notes to accounts.
                                        </p>
                                      </li>
                                      <li>
                                        <img src={purpleCheck} alt="" />
                                        <p>
                                          <strong>
                                            No More Excel Reporting:{" "}
                                          </strong>
                                          Saving more than 75% of time spent by
                                          accounting teams on finalising excel
                                          financials pack every year.
                                        </p>
                                      </li>
                                    </ul>
                                    <a
                                      href="#"
                                      className={[
                                        styles.mainBtn,
                                        styles.rtsBtn,
                                        styles.btnPrimary,
                                        "mt-4",
                                      ].join(" ")}
                                    >
                                      Learn More
                                    </a>
                                  </TabPanel>
                                  <TabPanel value="2">
                                    <ul>
                                      <li>
                                        <img src={purpleCheck} alt="" />
                                        <p>
                                          <strong>
                                            Quick Monthly Closure:
                                          </strong>
                                          Customized Reports for consistent
                                          management accounts pack.
                                        </p>
                                      </li>
                                      <li>
                                        <img src={purpleCheck} alt="" />
                                        <p>
                                          <strong>
                                            Working Paper Management:
                                          </strong>
                                          Manage your working papers at one
                                          place with our user-friendly
                                          templating tools that assist in
                                          digitalizing the records
                                        </p>
                                      </li>
                                      <li>
                                        <img src={purpleCheck} alt="" />
                                        <p>
                                          <strong>
                                            Achieve Efficient Collaboration:{" "}
                                          </strong>
                                          Communicate with team for all the
                                          financial review points & Improve the
                                          quality of reporting. No more back and
                                          forth mails for monthly close.
                                        </p>
                                      </li>
                                    </ul>
                                    <a
                                      href="#"
                                      className={[
                                        styles.mainBtn,
                                        styles.rtsBtn,
                                        styles.btnPrimary,
                                        "mt-4",
                                      ].join(" ")}
                                    >
                                      Learn More
                                    </a>
                                  </TabPanel>
                                </TabContext>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.imageArea}>
                  <img src={featureImg3} alt="feature_image" loading="lazy" />
                </div>
              </div>
              <div className={commonStyles.commonCta}>
                <Row>
                  <Col md={12} className="text-center">
                    <img
                      src={pattern}
                      className={commonStyles.pettern1}
                      alt=""
                    />
                    <h3>Get Early Access to our Financial Reporting Suite</h3>
                    <p>
                      Join our exclusive early access program to be among the
                      first to experience the power of our Financial Reporting
                      Suite. Automate your financial reporting with our AI
                      powered solutions that enable quick and accurate financial
                      reporting.
                    </p>
                    <Link
                      to="/login"
                      className={[styles.rtsBtn, styles.btnPrimary].join(" ")}
                    >
                      Get Started
                    </Link>
                    <img
                      src={pattern}
                      className={commonStyles.pettern2}
                      alt=""
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={[styles.featureArea, "pt-0"].join(" ")}>
        <Container>
          <Row className={styles.mt0}>
            <Col lg="12">
              <div
                className={[
                  styles.singleFeatureAreaStart,
                  styles.indexFeature,
                  styles.bgLight,
                  styles.columnReverse,
                ].join(" ")}
              >
                <div className={styles.imageArea}>
                  <img src={featureImg5} alt="feature_image" loading="lazy" />
                </div>
                <div className={styles.featureContentArea}>
                  <h2 className={styles.title}>Audit</h2>
                  <p className={styles.disc}>
                    Our modern auditing stack enhances quality of your audits so
                    that your audit documentation will withstand rigorous
                    quality assessments and legal scrutiny.
                  </p>
                  <ul>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Automate Audit Workflows: </strong> A
                        streamlined method improving audit accuracy, identifying
                        and documenting risks at every stage.
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong> AI Powered Test of Details: </strong>Our tool
                        uses advanced auditing to cross-verify source documents
                        with transactions and data sources for accuracy and
                        reliability.
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Paper Less Audits: </strong> Digitize the audit
                        documentation and reduce environmental footprint.
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong> Real Time Collaboration with Clients: </strong>{" "}
                        Facilitate audit team-client collaboration for seamless
                        communication and data sharing.
                      </p>
                    </li>
                  </ul>
                  <a
                    href="#"
                    className={[
                      styles.mainBtn,
                      styles.rtsBtn,
                      styles.btnPrimary,
                      "mt-4",
                    ].join(" ")}
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div
                className={[
                  styles.singleFeatureAreaStart,
                  styles.indexFeature,
                  styles.bgRedL,
                  styles.coreAcc,
                  "pb-5",
                ].join(" ")}
              >
                <div className={[styles.featureContentArea]}>
                  <h2 className={styles.title}>Core Accounting</h2>
                  <p className={styles.disc}>
                    Unleash the power of AI in your core accounting with our
                    platform for automated tasks, real-time insights, and
                    predictive growth strategies
                  </p>
                  <ul>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Automate Reconciliations: </strong> Eliminate
                        manual work with AI that automatically extracts data
                        from invoices and receipts, reconciles accounts, and
                        ensures data integrity.
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Real-Time Financial Visibility: </strong> Gain
                        instant insights with AI dashboards that highlight key
                        metrics and financial health.
                      </p>
                    </li>
                    <li>
                      <img src={purpleCheck} alt="" />
                      <p>
                        <strong>Predictive Analytics for Growth: </strong>{" "}
                        Forecast future cash flow, identify risks, and make
                        data-driven decisions for your business's success.
                      </p>
                    </li>
                  </ul>
                  <a
                    href="#"
                    className={[
                      styles.mainBtn,
                      styles.rtsBtn,
                      styles.btnPrimary,
                      "mt-4",
                    ].join(" ")}
                  >
                    Learn More
                  </a>
                </div>
                <div className={styles.imageArea}>
                  <img src={featureImg6} alt="feature_image" loading="lazy" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={commonStyles.roadMap}>
        <Container className={commonStyles.container}>
          <Row>
            <Col lg="12" className="text-center mb-2">
              <div className={commonStyles.roadmapHeading}>
                <h3>How It Works</h3>
                <p>
                  Simplify your path to robust Compliance, Financial Reporting,
                  and Auditing with Acont – a solution that works alongside your
                  existing accounting application.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className={`${commonStyles.roadmapBox} `}>
                <div className={commonStyles.numberList}>
                  <div className={commonStyles.numberwrap}>
                    <div className={commonStyles.numberHead}>
                      <h2>01</h2>
                    </div>
                    {/* <div className={commonStyles.numberStep}>
                      <span>step</span>
                    </div> */}
                  </div>
                </div>
                <h4>
                  Signup on <br></br>Acont
                </h4>
                <p>with your Business Details</p>
              </div>
            </Col>
            <Col md={4}>
              <div className={`${commonStyles.roadmapBox}`}>
                <div className={commonStyles.numberList}>
                  <div className={commonStyles.numberwrap}>
                    <div className={commonStyles.numberHead}>
                      <h2>02</h2>
                    </div>
                    {/* <div className={commonStyles.numberStep}>
                      <span>step</span>
                    </div> */}
                  </div>
                </div>
                <h4>
                  Monitor Compliances & Get
                  <br /> Real Time Updates
                </h4>
                <p>
                  Stay on top of your compliances to avoid litigation and
                  penalties
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className={`${commonStyles.roadmapBox} `}>
                <div className={commonStyles.numberList}>
                  <div className={commonStyles.numberwrap}>
                    <div className={commonStyles.numberHead}>
                      <h2>03</h2>
                    </div>
                    {/* <div className={commonStyles.numberStep}>
                      <span>step</span>
                    </div> */}
                  </div>
                </div>
                <h4>
                  Integrate your <br />
                  Accounting ERP
                </h4>
                <p>
                  Make faster decisions with Visual Data Analysis & Interactive
                  Dashboard
                </p>
              </div>
            </Col>
          </Row>
          <Row className={commonStyles.bottomBar}>
            <Col md={4}>
              <div className={`${commonStyles.roadmapBox} `}>
                <div className={commonStyles.numberList}>
                  <div className={commonStyles.numberwrap}>
                    <div className={commonStyles.numberHead}>
                      <h2>04</h2>
                    </div>
                    {/* <div className={commonStyles.numberStep}>
                      <span>step</span>
                    </div> */}
                  </div>
                </div>
                <h4>
                  Working Paper <br />
                  Management
                </h4>
                <p>
                  Digitize working papers and build strong internal controls for
                  Financial Reporting.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className={`${commonStyles.roadmapBox} `}>
                <div className={commonStyles.numberList}>
                  <div className={commonStyles.numberwrap}>
                    <div className={commonStyles.numberHead}>
                      <h2>05</h2>
                    </div>
                    {/* <div className={commonStyles.numberStep}>
                      <span>step</span>
                    </div> */}
                  </div>
                </div>
                <h4>
                  Financial<br></br> Reporting
                </h4>
                <p>
                  Achieve Quick Closure for your Management and Year End
                  Accounts
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className={`${commonStyles.roadmapBox} `}>
                <div className={commonStyles.numberList}>
                  <div className={commonStyles.numberwrap}>
                    <div className={commonStyles.numberHead}>
                      <h2>06</h2>
                    </div>
                    {/* <div className={commonStyles.numberStep}>
                      <span>step</span>
                    </div> */}
                  </div>
                </div>
                <h4>
                  Audit<br></br> Automation
                </h4>
                <p>
                  Automate Audit workflows & enable paperless collaboration for
                  seamless and high-quality audits
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="chart" className={commonStyles.comparedChart}>
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h3>How do we fare compared to other solutions?</h3>
            </Col>
          </Row>
          <div className="overscroll">
            <Row>
              <Col md={3}>
                <div className={commonStyles.fristFold}>
                  <div className={commonStyles.foldHeadings}>
                    <h5></h5>
                  </div>
                  <ul className={commonStyles.foldOne}>
                    <li>Focus</li>
                    <li>Data Import</li>
                    <li>Data Analysis</li>
                    <li>Automation</li>
                    <li>Audit Working Paper</li>
                    <li>Collaboration</li>
                    <li>Financial Reporting</li>
                    <li>Digital Signing of Financials</li>
                    <li>Management Reporting</li>
                    <li>Compliance Management</li>
                    <li>Company KPIs</li>
                    <li>User-Friendliness</li>
                    <li>Implementation Time</li>
                    <li>Cost</li>
                    <li>Customer Support</li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div className={commonStyles.secondFold}>
                  <div className={commonStyles.foldLogo}>
                    <img src={logo} alt="logo" />
                  </div>
                  <ul className={commonStyles.foldContent}>
                    <li>
                      Data analysis along with Automation of Audit and Financial
                      Reporting Process
                    </li>
                    <li>
                      Real Time Data Sync by integrations with accounting
                      software
                    </li>
                    <li>
                      Strong sampling tools, Built-in audit tests, Visualization
                      with customised Dashboards
                    </li>
                    <li>
                      Extensive scripting language allows for highly customized
                      automation of repetitive tasks.
                    </li>
                    <li>
                      Robust Audit Working Paper with Pre built Notes to
                      Accounts templates as per regulations
                    </li>
                    <li>
                      Advanced collaboration tools for team-based analysis and
                      Data sharing between Auditor & Auditee.
                    </li>
                    <li>
                      Ready to Sign Financial Statements in law compliant format
                    </li>
                    <li>Yes</li>
                    <li>Yes</li>
                    <li>Yes</li>
                    <li>Yes</li>
                    <li>Easier to learn and use</li>
                    <li>Less than a week</li>
                    <li>Flexible Pricing</li>
                    <li>Dedicated Account Manager & on Call Support</li>
                  </ul>
                </div>
              </Col>
              {/* <Col md={3} className={commonStyles.mobileView}>
              <div className={commonStyles.fristFold}>
                <div className={commonStyles.foldHeadings}>
                  <h5></h5>
                </div>
                <ul className={commonStyles.foldtext}>
                  <li>Focus</li>
                  <li>Data Import</li>
                  <li>Data Analysis</li>
                  <li>Automation</li>
                  <li>Audit Working Paper</li>
                  <li>Collaboration</li>
                  <li>Financial Reporting</li>
                  <li>Digital Signing of Financials</li>
                  <li>Management Reporting</li>
                  <li>Compliance Management</li>
                  <li>Company KPIs</li>
                  <li>User-Friendliness</li>
                  <li>Implementation Time</li>
                  <li>Cost</li>
                  <li>Customer Support</li>
                </ul>
              </div>
            </Col> */}
              <Col md={5}>
                <div className={commonStyles.thirdFold}>
                  <div className={commonStyles.foldHeading}>
                    <h5>Legacy Financial Reporting / Audit Tools</h5>
                  </div>
                  <ul className={commonStyles.foldtext}>
                    <li>Data Analysis & Audit</li>
                    <li>
                      Manual Data Import with supported formats (XML, Json,
                      Excel, CSV, etc.). Limited integrations with accounting
                      software
                    </li>
                    <li>Limited Audit Tools and Dashboards</li>
                    <li>
                      Limited scripting capabilities, relies on pre-built
                      workflows.
                    </li>
                    <li>Audit Working papers with Standard Templates</li>
                    <li>
                      Basic collaboration features, primarily focused on
                      individual user analysis
                    </li>
                    <li>
                      Prepare Financial Statemetns Manually in Spreadsheets
                    </li>
                    <li>No</li>
                    <li>No</li>
                    <li>No</li>
                    <li>No</li>
                    <li>Extensive training required</li>
                    <li>One Month</li>
                    <li>More expensive</li>
                    <li>Limited Support</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className={commonStyles.bottomCta}>
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <div className={commonStyles.ctaBanner}>
                <h3>
                  Stay in the loop! Subscribe to our newsletter for exciting
                  updates and offers...!
                </h3>
                <p>Be the first to know about releases and industry insights</p>
                <form>
                  <div className={commonStyles.formGroup}>
                    
                    <input
                      type="email"
                      placeholder="Email Address"
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();  // Prevent default form behavior
                          handleSubscribe()
                        }
                      }}
                      onChange={(e) => {
                        setSubscribeEmail(e.target.value);
                      }}
                    />
                    {emailError || emailEmptyError ? (
                      <p style={{ color: "red" }}>{emailEmptyError ? 'Please enter your email address.' : 'Please enter a valid email address.'}</p>
                    ) : null}
 
                    <Button variant="primary" onClick={handleSubscribe}>
                      {isLoading ? <Spinner /> : "Subscribe"}
                    </Button>
                    
                    <Modal
                      show={showRegisteredModal}
                      onHide={handleCloseRegisteredModal}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <img src={modalCross} alt="" />
                        <h3>
                          Email already registered.
                          <br />
                          Use a new one for newsletter.
                        </h3>
                      </Modal.Body>
                    </Modal>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body>
                        <img src={modalCheck} alt="" />
                        <h3>
                          "You're in...! Welcome to the Acont Community. Get
                          ready for exclusive updates."
                        </h3>
                        <Link to="/login">Proceed to Sign Up</Link>
                      </Modal.Body>
                      {/* <Modal.Footer>

                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                          Save Changes
                        </Button>
                      </Modal.Footer> */}
                    </Modal>
                  </div>
                </form>
                <small>
                  We care about your data in our{" "}
                  <a href="/privacy-policy">privacy policy.</a>
                </small>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className={commonStyles.aboutUs}>
        <Container>
          <Row>
            <Col md={6}>
              <div className="text-left">
                <span>About Us</span>
                <h3>Streamlined Process for Delivering Financial Solution</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the dummy text ever
                  since the 1500s
                </p>
                <img src={about} alt="" />
              </div>
            </Col>
            <Col md={6}>
              <ul>
                <li>
                  <div className={commonStyles.img}>
                    <img src={featuredIcon1} alt="" />
                  </div>
                  <div className={commonStyles.text}>
                    <h4>Initial Consultation</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been
                    </p>
                  </div>
                </li>
                <li>
                  <div className={commonStyles.img}>
                    <img src={featuredIcon2} alt="" />
                  </div>
                  <div className={commonStyles.text}>
                    <h4>Analysis & Assesment</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been
                    </p>
                  </div>
                </li>
                <li>
                  <div className={commonStyles.img}>
                    <img src={featuredIcon3} alt="" />
                  </div>
                  <div className={commonStyles.text}>
                    <h4>Strategic Planning</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been
                    </p>
                  </div>
                </li>
                <li>
                  <div className={commonStyles.img}>
                    <img src={featuredIcon4} alt="" />
                  </div>
                  <div className={commonStyles.text}>
                    <h4>Implementation & Execution</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been
                    </p>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section> */}
      <div
        id="faq"
        className={[styles.rtsFaqArea, styles.rtsSectionGap, styles.bgFaq].join(
          " "
        )}
      >
        <Container>
          <Row>
            <Col lg="12">
              <div className={styles.titleConterArea}>
                <h2 className={styles.title}>Frequently Asked Questions</h2>
              </div>
            </Col>
          </Row>
          <Row className={styles.mt40}>
            <Col lg="12">
              <div className={styles.accordionAreaOne}>
                <Accordion
                  className={styles.accordion}
                  id="accordionExample"
                  defaultActiveKey="0"
                >
                  {faqData.map((val, i) => {
                    return (
                      <Accordion.Item
                        key={String(val.id)}
                        className={styles.accordionItem}
                        eventKey={String(i)}
                      >
                        <Accordion.Header
                          onClick={() => onClickFaqHeader(val.id)}
                          id={val.id}
                        >
                          <button
                            className={[
                              commonStyles.accordionButton,
                              commonStyles.collapsed,
                              "accordion-button",
                            ].join(" ")}
                            type="button"
                          >
                            <span>{val?.title}</span>
                            {val.id === faqSelected ? (
                              <img
                                src={upArrow}
                                className={`${commonStyles.upArrow} upArrow`}
                                alt=""
                              />
                            ) : (
                              <img
                                src={downArrow}
                                className={`${commonStyles.downArrow} downArrow`}
                                alt=""
                              />
                            )}
                          </button>
                        </Accordion.Header>
                        <Accordion.Body className={styles.accordionBody}>
                          <p>{val.desc}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}

                  {/* <Accordion.Item className={styles.accordionItem} eventKey="1">
                    <Accordion.Header id="headingTwo">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>How does Acont demonstrate its value proposition for accountants and Auditing firms?</span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont helps accountants save time, improve accuracy, gain real-time insights, and mitigate risks, ultimately leading to better decision-making and enhanced client service.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="2">
                    <Accordion.Header id="headingThree">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        How does Acont improve the accuracy of financial reporting?
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont utilizes AI-powered automation and data validation checks to minimize errors and ensure data integrity.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="3">
                    <Accordion.Header id="headingFour">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span> What kind of Real-Time insights does Acont provide?</span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont offers Real-Time dashboards and Key matrix on financial performance, compliance status, and potential risks, allowing for proactive decision-making.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="4">
                    <Accordion.Header id="headingFive">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        How much time can Acont save on financial reporting and audits?
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont can save 70-75% of time spent on the financial reporting and audit timeframes by automating tasks and streamlining processes. The exact time saved will depend on the complexity of your financial operations.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item className={styles.accordionItem} eventKey="5">
                    <Accordion.Header id="headingSix">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        What AI capabilities does Acont utilize? 
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont leverages AI for tasks such as anomaly detection, risk identification, Vouching, Data Mapping, and Intelligent data analysis, enhancing efficiency and accuracy.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="7">
                    <Accordion.Header id="headingSeven">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        Can Acont integrate with existing accounting software and systems?
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont offers seamless integration with various accounting software and systems, ensuring smooth data flow and minimizing manual data import.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="8">
                    <Accordion.Header id="headingEight">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        What level of technical expertise is required to use Acont effectively?
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont boasts a user-friendly interface and requires minimal technical expertise for setup and operation.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="9">
                    <Accordion.Header id="headingNine">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        What level of accounting expertise is required to use Acont effectively?
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont is designed for accountants of all levels. While basic accounting knowledge is beneficial, the platform offers intuitive user interfaces and training resources to ensure smooth adoption.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="10">
                    <Accordion.Header id="headingTen">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        Does Acont offer training and support for users? 
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont provides comprehensive training materials and ongoing support to ensure users can maximize the platform's capabilities.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="11">
                    <Accordion.Header id="headingEleven">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        What are the security measures in place to protect my financial data? 
                        </span>
                        <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont utilizes industry-standard security protocols and data encryption to ensure the highest level of data security. The data will be stored in the Server located in India.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="13">
                    <Accordion.Header id="headingTwelev">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        What pricing plans does Acont offer? 
                        </span>
                         <img src={downArrow} className={`${commonStyles.downArrow} downArrow`} alt="" />
                        <img src={upArrow} className={`${commonStyles.upArrow} upArrow`} alt="" />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Acont offers various pricing plans based on company size, features required, and number of users.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accordionItem} eventKey="14">
                    <Accordion.Header id="headingThirteen">
                      <button
                        className={[
                          commonStyles.accordionButton,
                          commonStyles.collapsed,
                          "accordion-button",
                        ].join(" ")}
                        type="button"
                      >
                        <span>
                        Does Acont offer a free trial? 
                        </span>
                        <img
                          src={downArrow}
                          className={commonStyles.downArrow}
                          alt=""
                        />
                        <img
                          src={upArrow}
                          className={commonStyles.upArrow}
                          alt=""
                        />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordionBody}>
                      <p>
                      Yes, Acont offers a free trial for 2 weeks to allow potential users to experience the platform firsthand.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className={commonStyles.footerLinks}>
                <a href="/" className="logo">
                  <img src={logo} alt="logo" />
                </a>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  {/* <li>
                    <a href="#">About Us</a>
                  </li> */}
                  <li>
                    <a href="#faq">FAQ</a>
                  </li>
                  {/* <li>
                    <a href="#">Help</a>
                  </li> */}
                  <li>
                    <a href="/privacy-policy">Privacy</a>
                  </li>
                  {/* <li>
                    <a href="#">Terms & Conditions</a>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col md={6} className="text-end">
              <form>
                <div className={commonStyles.formGroup}>
                  <input type="text" placeholder="Email Address" />
                  <button
                    style={{ marginLeft: "8px" }}
                    onClick={() => navigate("/login")}
                  >
                    Get Started
                  </button>
                </div>
              </form>
            </Col>
          </Row>
          <hr />
          <Row className={commonStyles.copyright}>
            <Col md={6}>
              <div className={commonStyles.copyrightLink}>
                <p>
                  Copyright © 2024 Acontway Technologies Pvt Ltd. - All Rights
                  Reserved.
                </p>
              </div>
            </Col>
            <Col md={6} className="text-end">
              <ul>
                <li>
                  <a href="/terms-conditions">Terms</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className={styles.progressWrap}>
        <svg
          className={[styles.progressCircle, "svg-content"].join(" ")}
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            // style="
            //   transition: stroke-dashoffset 10ms linear 0s;
            //   stroke-dasharray: 307.919, 307.919;
            //   stroke-dashoffset: 307.919;
            // "
          ></path>
        </svg>
        <div className={styles.arrowUp}>
          <img src="" alt="" />
        </div>
      </div>
    </>
  );
};

export default Home;
