/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import backBtn from "../../../assets/images/back-btn.png";
import editBtn from "../../../assets/images/edit_Button.png";
import commonStyles from "../../../common/commonStyles.module.css";
import ModalComp from "../../../components/ModalComp";
import Spinner from "../../../components/Spinner";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { handleSuccess } from "../../../utils/helperFunction";
import { CompanyDetail } from "../CompanySetup2/api";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onClickEdit = (index) => {
    navigate(`${RoutesUrl.COMPANY_DETAILS_EDIT}/${index}`);
  };

  const onAddMore = () => {
    if (userData?.company_type === 3) {
      //multiple company
      navigate("/company-setup-details");
      return;
    } else {
      navigate("/add-more-location");
    }
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const res = await CompanyDetail(userData);
      setLoading(false);
      if (res && res.success) {
        handleSuccess(navigate, dispatch);
      }
    } catch (err) {
      setLoading(false);
      console.error("Error sending email:", err);
      toast.error(err?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      className={[commonStyles.authOutter, commonStyles.registerOutter].join(
        " "
      )}
    >
      <Container>
        <Row>
          <Col md={12}>
            <div className={commonStyles.registerCard}>
              <div className={commonStyles.formWizard}>
                <div className="myContainer"></div>
                <div
                  className={[commonStyles.formContainer, "animated"].join(" ")}
                >
                  <h2
                    className={["text-left", commonStyles.formTitle].join(" ")}
                  >
                    Company Details
                  </h2>
                  <hr />
                  <form>
                    <Row
                      className="customscrollBar"
                      style={{
                        flexWrap: "nowrap",
                        display: "flex",
                        overflowX: "auto",
                      }}
                    >
                      {userData?.company_setup_details.map((val, i) => {
                        return (
                          <Col key={String(i)} md={6}>
                            <div className={commonStyles.userCard}>
                              <ol>
                                <li>
                                  <h4>
                                    <span>
                                      {val?.company_name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </span>
                                  </h4>
                                  <h3>{val?.company_name}</h3>
                                </li>
                                <li>
                                  <a href="#" onClick={() => onClickEdit(i)}>
                                    <img src={editBtn} alt="" />
                                  </a>
                                </li>
                              </ol>
                              <hr />
                              <ul>
                                <li>Employees</li>
                                <li>{val?.employee_strength}</li>
                                <li>Country</li>
                                <li>{val?.country}</li>
                                <li>State</li>
                                <li>{val?.country_state}</li>
                                <li>Location</li>
                                <li>{val?.address}</li>
                                <li>Revenue</li>
                                <li>{val?.revenue}</li>
                                <li>GST Number</li>
                                <li>{val?.gst_license_number}</li>
                                <li>Industry</li>
                                <li>{val?.domain_industry}</li>
                              </ul>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>

                    <div
                      className={[
                        commonStyles.formGroup,
                        "text-center mar-b-0 justify-content-between d-flex",
                      ].join(" ")}
                    >
                      <div className={commonStyles.backBtn}>
                        <Link
                          to="/company-setup-details"
                          value="BACK"
                          className={[
                            "btn btn-default",
                            commonStyles.back,
                          ].join(" ")}
                        >
                          <img src={backBtn} alt="" /> Back
                        </Link>
                      </div>
                      <div className={commonStyles.nextBtns}>
                        <button
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default form submission behavior
                            onAddMore(); 
                          }}
                          className={commonStyles.locationBtn}
                        >
                          {userData.company_type === 3
                            ? "Add more"
                            : "Add more Entity / Location"}
                        </button>

                        <button
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default form submission behavior
                            onFinish(); //
                          }}
                          type="submit"
                          className={commonStyles.next}
                        >
                          {isLoading ? <Spinner /> : "Finish"}
                        </button>
                        {/* <Link
                          onClick={onFinish}
                          className={[commonStyles.next, "submit"].join(" ")}
                        >
                          Finish
                        </Link> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <ModalComp
          isVisible={showModal}
          handleClose={() => setShowModal(false)}
        />
      </Container>
    </section>
  );
};

export default CompanyDetails;
