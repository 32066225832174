import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import backBtn from "../../../assets/images/back-btn.png";
import check from "../../../assets/images/check.svg";
import commonStyles from "../../../common/commonStyles.module.css";
import { setUser } from "../../../redux/slices/userSlice";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { companySetupValidationSchema } from "./validation";

const CompanySetup = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const initialValues = { company_type: String(userData?.company_type) || "" };

  const submitRegister = async (values) => {
    const params = {
      ...userData,
      company_type: Number(values.company_type),
      company_setup_details: [], //reset these values
      multiple_branch: [], // reset these values
    };

    dispatch(setUser(params));
    navigate(RoutesUrl.COMPANY_SETUP_DETAILS);
  };

  return (
    <section
      className={[commonStyles.authOutter, commonStyles.registerOutter].join(
        " "
      )}
    >
      <Container>
        <Row>
          <Col md={12}>
            <div className={commonStyles.registerCard}>
              <div className={commonStyles.formWizard}>
                <div className="myContainer"></div>
                <div
                  className={[commonStyles.formContainer, "animated"].join(" ")}
                >
                  <h2
                    className={["text-left", commonStyles.formTitle].join(" ")}
                  >
                    Company Setup Details
                  </h2>
                  <hr />
                  <h4 className={commonStyles.companyType}>Company type</h4>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={companySetupValidationSchema}
                    onSubmit={(values, { setSubmitting }) =>
                      submitRegister(values, setSubmitting)
                    }
                  >
                    {({ isSubmitting }) => (
                      <Form className={commonStyles.nextFormStep}>
                        <Row className="row-flex">
                          <Col lg={4} md={6} sm={6} >
                            <label
                              className={[
                                commonStyles.radioContainer,
                                commonStyles.formControl,
                              ].join(" ")}
                            >
                              <h4>Single</h4>
                              <Field
                                type="radio"
                                name="company_type"
                                value="1"
                              />
                              <span className={commonStyles.checkmark}></span>
                              <hr />
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s,
                              </p>
                              <small>
                                <img alt="img-text" src={check} /> Ipsum is simply dummy text
                                of the printing.
                              </small>
                            </label>
                          </Col>
                          <Col lg={4} md={6} sm={6} >
                            <label
                              className={[
                                commonStyles.radioContainer,
                                commonStyles.formControl,
                              ].join(" ")}
                            >
                              <h4>Multiple Branch</h4>
                              <Field
                                type="radio"
                                name="company_type"
                                value="2"
                              />
                              <span className={commonStyles.checkmark}></span>
                              <hr />
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s,
                              </p>
                              <small>
                                <img alt="img-text-2" src={check} /> Ipsum is simply dummy text
                                of the printing.
                              </small>
                            </label>
                          </Col>
                          <Col lg={4} md={6} sm={6} >
                            <label
                              className={[
                                commonStyles.radioContainer,
                                commonStyles.formControl,
                              ].join(" ")}
                            >
                              <h4>Multiple Industry</h4>
                              <Field
                                type="radio"
                                name="company_type"
                                value="3"
                              />
                              <span className={commonStyles.checkmark}></span>
                              <hr />
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s,
                              </p>
                              <small>
                                <img alt="img-text-3" src={check} /> Ipsum is simply dummy text
                                of the printing.
                              </small>
                            </label>
                          </Col>
                          <ErrorMessage
                            name="company_type"
                            component="div"
                            className="error"
                          />
                        </Row>
                        <div
                          className={[
                            commonStyles.formGroup,
                            "text-center d-flex justify-content-between",
                          ].join(" ")}
                        >
                          <Link
                            to="/register"
                            value="BACK"
                            className={[
                              "btn btn-default ",
                              commonStyles.back,
                            ].join(" ")}
                          >
                            <img alt="img-text-4" src={backBtn} /> Back
                          </Link>
                         
                          <button className="customCta" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Next"}
                          </button>
                        
                         
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompanySetup;
